@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* margin: 0; */
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 1em; */
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}



